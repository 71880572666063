/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h1: "h1",
    a: "a",
    span: "span",
    ul: "ul",
    li: "li",
    strong: "strong",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Portfolios document and share design work. On the one hand, they are a recruiters prime tool for screening efficiently. On the other, they are platform for designers to show off. So what's my gripe with them?"), "\n", React.createElement(_components.h1, {
    id: "the-problem"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#the-problem"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "The Problem"), "\n", React.createElement(_components.p, null, "First let's get this out the way."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "This is my portfolio."), "\n", React.createElement(_components.li, null, "The first post I write is about how portfolios have issues."), "\n"), "\n", React.createElement(_components.p, null, "That might seem hypocritical, but it's done with intent."), "\n", React.createElement(_components.p, null, "I believe portfolios are great at ", React.createElement(_components.strong, null, "selling"), " stories. Both through imagery and text."), "\n", React.createElement(_components.p, null, "Showing and telling."), "\n", React.createElement(_components.p, null, "A lot of design is tied to those two things. Communication effectively, and using the means necessary to make that happen."), "\n", React.createElement(_components.p, null, "The issues I have with design portfolio are twofold. Both related to implicit incentives due to constraints of the format we are using."), "\n", React.createElement(_components.h2, {
    id: "porfolios-are-great-at-showing-visuals"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#porfolios-are-great-at-showing-visuals"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "Porfolios are great at showing visuals."), "\n", React.createElement(_components.p, null, "This incentivices beautiful graphic design and mockups over case studies and thorough descriptions."), "\n", React.createElement(_components.p, null, "I am guilty of doing this."), "\n", React.createElement(_components.p, null, "The problem is that it rewards the designers who are good at graphic design, which is only a meager part of the work."), "\n", React.createElement(_components.p, null, "Additionally, so much of how a product looks depends on other factors out of the designers hands."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The brand of the company"), "\n", React.createElement(_components.li, null, "The existing design system"), "\n", React.createElement(_components.li, null, "The skills of their frontend developers (if you evaluate the final product)"), "\n"), "\n", React.createElement(_components.p, null, "I know a lot of designers who are great at their job, but when it comes to making something beautiful, they struggle."), "\n", React.createElement(_components.p, null, "Do portfolios help you find these candidates? I'm not sure. I feel like we risk them getting swept by during screenings. Then again, maybe that's for their better. I wouldn't want to work for a place where they valued the visuals over the results anyway. The problem is that it's harder to get past the doorway."), "\n", React.createElement(_components.h2, {
    id: "porfolios-can-get-wordy"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#porfolios-can-get-wordy"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "Porfolios can get wordy."), "\n", React.createElement(_components.p, null, "Because of the format that delivers the story, it's either going to be delivered through visuals or text. You have the rare audio/video, which is nice, but they are often left to a selected few."), "\n", React.createElement(_components.p, null, "The easy bet is to go for a good old text.\nAnd once you are doing text, you have to write a pretty large amount of content to actually convey what you did. If you don't, the story you are trying to tell won't provide enough context to be useful for the reader."), "\n", React.createElement(_components.p, null, "Not only is providing enough context is time consuming, it also reduces the chance that the reader — which is most likely looking through multiple portfolios, let's be honest — is to actually read through everything."), "\n", React.createElement(_components.p, null, "A good writer can do this well, and it incentives people who are good at selling themselves through text. That leaves out a bunch of good designers, who are great at communicating in real life situations."), "\n", React.createElement(_components.h2, {
    id: "so-whats-the-problem"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#so-whats-the-problem"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "So what's the problem?"), "\n", React.createElement(_components.p, null, "The stuff I have mentioned is well known. But my issue is this:"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Porfolios don't cover the stuff about design that is difficult.")), "\n", React.createElement(_components.p, null, "How do you share with others how you established relations with other people?"), "\n", React.createElement(_components.p, null, "How you enabled a foundation of trust and engagement in your team?"), "\n", React.createElement(_components.p, null, "Got the trust of stakeholders?"), "\n", React.createElement(_components.p, null, "Most of the difficult parts of design, is in my opinion, people and politics. Unfortunately it's difficult to convey this in portfolios. It could also be because a lot of designers with portfolios aren't spending their time on their people skills, but on crafting artifacts instead."), "\n", React.createElement(_components.p, null, "It's a fun tale that most good designers probably won't have time to work on their portfolio. They are too busy dealing with real design work."), "\n", React.createElement(_components.h2, {
    id: "what-to-do"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#what-to-do"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "What to do?"), "\n", React.createElement(_components.p, null, "I don't have a good solution, except to investigate other forms of conveying design experience. Maybe I'll get into finding some alternatives one day."), "\n", React.createElement(_components.p, null, "Until then, I believe that following Jared Spools advice on recruiting is probably a good temporary solution."), "\n", React.createElement(_components.p, null, "--"), "\n", React.createElement(_components.p, null, "Thanks for reading. I get a bit ranty sometimes."), "\n", React.createElement(_components.p, null, "I'll add more to this article once I find the time. Now I gotta push this site into production. Wish me luck!"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
